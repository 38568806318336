import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "modal",
  ];

  connect() {
    this.initializeControls();
    this.initializeInputs();
    this.startValidationMonitor();
    this.initializeAddButtons();
  }

  initializeAddButtons() {
    const addButtons = document.querySelectorAll('[data-modal-target]');
    const sectionWrapperBlocks = document.querySelectorAll('.accordion-section-wrapper');
    addButtons.forEach((button) => {
      button.addEventListener("click", () => {
        sectionWrapperBlocks.forEach(sectionWrapper => {
          let sectionBlock = sectionWrapper.children[0]
          sectionWrapper.classList.add('collapsed');
          sectionBlock.classList.remove('show');
          sectionBlock.classList.remove('collapsed');
        })
        let currentSectionWrapper = document.querySelector(button.dataset.modalTarget).closest(".accordion-section-wrapper");
        let currentSection = document.querySelector(button.dataset.modalTarget).closest(".section");
        let currentField = document.querySelector(button.dataset.modalTarget).closest(".field");
        if (currentSectionWrapper !== null) {
          currentSectionWrapper.classList.remove("collapsed");
          currentSectionWrapper.classList.add("show");
          currentSectionWrapper.children[0].classList.remove('collapsed');
        }
        if (currentSection !== null) {
          currentSection.classList.add("show");
        }

        if (currentSectionWrapper !== null && currentField !== null) {
          setTimeout(function () {
            const sectionNames = ["brands", "concept", "product_service", "social_media"];

            if (sectionNames.includes(button.dataset.test)) {
              currentSectionWrapper.scrollIntoView({behavior: 'smooth', block: 'start'});
            } else {
              currentField.scrollIntoView({behavior: 'smooth', block: 'start'})
            }
          }, 400);
        }
      });
    });
  }

  initializeControls() {
    const saveButtons = document.querySelectorAll(".submit-btn");
    saveButtons.forEach((item) => {
      item.addEventListener("click", () => {
        this.saveProjectTemplate();
      });
    });
  }

  initializeInputs() {
    const input = document.querySelector('input.select-text');
    if (input !== null) {
      input.addEventListener("click", () => {
        input.focus();
        input.select();
      });
    }
  }

  updateSectionIndicator(section) {
    function validateImageResolution(logoUploaderId, width, height) {
      return new Promise((resolve, reject) => {
        const logoUploaderDiv = document.getElementById(logoUploaderId).querySelector('.preview');
        const computedStyle = window.getComputedStyle(logoUploaderDiv);
        const backgroundImageUrl = computedStyle.getPropertyValue('background-image');

        if (backgroundImageUrl === 'url("")') {
          resolve(false);
          return;
        }

        const decodedUrl = decodeURIComponent(backgroundImageUrl.replace(/url\("(.+?)"\)/, '$1'));
        const img = new Image();
        img.src = decodedUrl;

        img.onload = function() {
          resolve(this.width >= width && this.height >= height);
        };

        img.onerror = () => {
          resolve(false);
        };
      });
    }

    function validateURL(urlString) {
      const URL_REGEX = /^((https?):\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

      const match = urlString.match(URL_REGEX);
      return match && match[0].length <= 200;
    }

    const validatedInputs = Array.from(section.querySelectorAll('[data-validate="true"]'));
    const validationsPromises = validatedInputs.map(input => {
      if (input.name === 'project_template[description]') {
        return (input.value.length > 200);
      }
      if (input.name === 'project_template[website]') {
        return validateURL(input.value);
      }
      if (input.name === 'project_template[facebook_id]') {
        return validateURL(input.value);
      }
      if (input.name === 'project_template[twitter_id]') {
        return validateURL(input.value);
      }
      if (input.name === 'project_template[instagram_id]') {
        return validateURL(input.value);
      }
      if (input.name === 'project_template[overall_look]') {
        return (input.value.length > 200);
      }
      if (input.name === 'project_template[shops_experience]' && input.type == "radio") {
        return document.querySelector('input[name="project_template[shops_experience]"]:checked')
      }
      if (input.name === 'project_template[popups_experience]' && input.type == "radio") {
        return document.querySelector('input[name="project_template[popups_experience]"]:checked')
      }
      if (input.name === 'project_template[prices_from]') {
        let prices_to = parseInt(document.querySelector('input[name="project_template[prices_to]"]').value);
        return (parseInt(input.value) <= prices_to && parseInt(input.value) >= 0);
      }
      if (input.name === 'project_template[prices_to]') {
        let prices_from = parseInt(document.querySelector('input[name="project_template[prices_from]"]').value);
        return (parseInt(input.value) >= prices_from);
      }
      if (input.name === 'project_template[tags][]') {
        let tagsCheckboxesArray = Array.from(document.querySelectorAll('input[type="checkbox"][name="project_template[tags][]"]'));
        let tagsAnyChecked = tagsCheckboxesArray.some(checkbox => checkbox.checked);

        return tagsAnyChecked
      }
      if (input.name === 'project_template[industry][]') {
        let industryCheckboxesArray = Array.from(document.querySelectorAll('input[type="checkbox"][name="project_template[industry][]"]'));
        let industryAnyChecked = industryCheckboxesArray.some(checkbox => checkbox.checked);

        return industryAnyChecked
      }
      if (input.name === 'brand[logo]') {
        return validateImageResolution('logo-fileupload-brand-logo', 600, 600);
      }
      if (input.name === 'brand[image]') {
        return validateImageResolution('logo-fileupload-brand-image', 1200, 800);
      }
      return !!input.value;
    });

    Promise.all(validationsPromises).then(results => {
      const filled = results.every(result => result);
      const sectionHeader = document.querySelector(`.section__header[data-target="#${section.getAttribute("id")}"]`);
      if (filled) {
        section.querySelectorAll(".input__error").forEach((errorHolder) => {
          errorHolder.innerHTML = "";
          errorHolder.classList.add("is-hidden");
        });
        sectionHeader.classList.remove("invalid");
        sectionHeader.classList.add("filled");
      } else {
        sectionHeader.classList.remove("filled");
      }
    });
  }

  startValidationMonitor() {
    const that = this;
    this.validationTimer = setInterval(() => {
      document
          .querySelectorAll(
              "#brands_section, #concept_section, #product_services_section, #social_media_section"
          )
          .forEach((section) => {
            that.updateSectionIndicator(section);
          });
    }, 300);
  }

  saveProjectTemplate() {
    event.preventDefault();
    const form = document.getElementById("project_template_form");
    const method = form.getAttribute("data-method");
    const action = form.getAttribute("data-action");
    const form_name = event.target.attributes["data-form-name"].nodeValue;

    let dataToSend = {};

    let tagsCheckboxes = document.querySelectorAll('.project_template_tags input[type="checkbox"]');
    const selectedTags = [];
    tagsCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        selectedTags.push(checkbox.value);
      }
      if (selectedTags.length === 0) {
        selectedTags.push("");
      }
    });

    let industryCheckboxes = document.querySelectorAll('.project_template_industry input[type="checkbox"]');
    const selectedIndustry = [];
    industryCheckboxes.forEach(checkbox => {
      if (checkbox.checked) {
        selectedIndustry.push(checkbox.value);
      }
      if (selectedIndustry.length === 0) {
        selectedIndustry.push("");
      }
    });

    if (form_name === "brands") {
      dataToSend = {
        name: document.getElementById("project_template_brand_name").value,
        tags: selectedTags,
        title: document.getElementById("project_template_title").value,
        is_public: document.getElementById("set_public").checked,
        description: document.getElementById("project_template_description").value,
        employee_count: document.getElementById("project_template_employee_count").value,
        currency: document.getElementById("project_template_currency").value.toLowerCase(),
        year_founded: document.getElementById("project_template_year_founded").value,
        based: document.getElementById("project_template_based").value,
        website: document.getElementById("project_template_website").value,
      };
    } else if (form_name === "concepts") {
      dataToSend = {
        industry: selectedIndustry,
        overall_look: document.getElementById("project_template_overall_look").value,
        shops_experience: document.getElementById("project_template_shops_experience")?.type === "text" ?
                          document.getElementById("project_template_shops_experience").value :
                          document.querySelector('.active input[name="project_template[shops_experience]"]')?.value,
        existing_shops_info: document.getElementById("project_template_existing_shops_info").value,
        popups_experience: document.getElementById("project_template_popups_experience")?.type === "text" ?
                           document.getElementById("project_template_popups_experience").value :
                           document.querySelector('.active input[name="project_template[popups_experience]"]')?.value,
        max_budget: document.getElementById("project_template_max_budget").value !== '' ?
            document.getElementById("project_template_max_budget").value : 0,
      };
    } else if (form_name === "product_services") {
      dataToSend = {
        prices_from: document.getElementById("project_template_prices_from").value,
        prices_to: document.getElementById("project_template_prices_to").value,
      };
    } else if (form_name === "social_media") {
      dataToSend = {
        facebook_id: document.getElementById("project_template_facebook_id").value,
        twitter_id: document.getElementById("project_template_twitter_id").value,
        instagram_id: document.getElementById("project_template_instagram_id").value,
      };
    } else if (form_name === "active") {
      dataToSend = {
        is_active: document.getElementById("set_active").checked,
      }
    } else {
      dataToSend = {
        name: document.getElementById("project_template_brand_name").value,
        tags: selectedTags,
        title: document.getElementById("project_template_title").value,
        is_public: document.getElementById("set_public").checked,
        description: document.getElementById("project_template_description").value,
        employee_count: document.getElementById("project_template_employee_count").value,
        currency: document.getElementById("project_template_currency").value.toLowerCase(),
        year_founded: document.getElementById("project_template_year_founded").value,
        based: document.getElementById("project_template_based").value,
        website: document.getElementById("project_template_website").value,
        form_name: event.target.attributes["data-form-name"].nodeValue,
        overall_look: document.getElementById("project_template_overall_look").value,
        shops_experience: document.getElementById("project_template_shops_experience")?.type === "text" ?
                          document.getElementById("project_template_shops_experience").value :
                          document.querySelector('.active input[name="project_template[shops_experience]"]')?.value,
        existing_shops_info: document.getElementById("project_template_existing_shops_info").value,
        popups_experience: document.getElementById("project_template_popups_experience")?.type === "text" ?
                           document.getElementById("project_template_popups_experience").value :
                           document.querySelector('.active input[name="project_template[popups_experience]"]')?.value,
        industry: selectedIndustry,
        max_budget: document.getElementById("project_template_max_budget").value !== '' ?
            document.getElementById("project_template_max_budget").value : 0,
        prices_from: document.getElementById("project_template_prices_from").value,
        prices_to: document.getElementById("project_template_prices_to").value,
        facebook_id: document.getElementById("project_template_facebook_id").value,
        twitter_id: document.getElementById("project_template_twitter_id").value,
        instagram_id: document.getElementById("project_template_instagram_id").value,
      };
    }

    $.ajax({
      url: action,
      type: method,
      dataType: "json",
      data: {
        project_template: dataToSend,
        form_name: form_name,
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    }).done((data) => {
      if (data && data.errors) {
        this.showErrorNotifications(data.errors);
      } else {
        if (form_name === "global_new") {
          const project_template_id = document.querySelector(".submit-btn").getAttribute('data-project-template-id');
          Turbolinks.visit(window.location.pathname.replace('new', project_template_id +'/edit'))
        } else {
          Turbolinks.visit(window.location.href);
        }
      }
    });
  }

  showErrorNotifications(errors) {
    document.querySelectorAll(".modal__project_template .input__error").forEach((errorHolder) => {
      errorHolder.innerHTML = "";
      errorHolder.classList.add("is-hidden");
    });

    document.querySelectorAll(".modal__project_template .accordion-section").forEach((section) => {
      section.classList.remove("invalid");
    });

    Object.entries(errors).forEach((error) => {
      const fieldName = error[0];
      const errorMessage = error[1].join(', ');
      const errorHolder = document.getElementById(`project_template_${fieldName}_error`);
      const section = document.querySelector(`.accordion-section.${errorHolder?.getAttribute("data-section")}`);
      section.classList.add("invalid");
      errorHolder.classList.remove("is-hidden");
      document.querySelectorAll("#modal__global_new .collapse").forEach((section) => {
        section.classList.remove('show')
        section.parentElement.classList.add('collapsed')
      });
      document.querySelector(".invalid").parentElement.querySelector('.collapse').classList.add('show')
      errorHolder.parentElement.parentElement.parentElement.classList.add("show");
      document.querySelector(".collapse").closest('form .collapse').classList.add('show')
      let closestElement = errorHolder.closest('form .collapse');
      if (closestElement !== null) {
        errorHolder.closest('form .collapse').classList.add("show");
      }
      errorHolder.classList.remove("collapsed");
      errorHolder.parentElement.classList.remove("collapsed");
      errorHolder.innerHTML = errorMessage;
      let first_error_input = document.querySelector(".invalid");
      if (first_error_input !== null) {
        first_error_input.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    });
  }
}
